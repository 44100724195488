export const dashboard = {
  titlePre: '提供',
  title: '为您的AI/ML项目加速',
  desc: '利用最新的GPU和流行框架的强大功能，更快、更高效地运行您的AI/ML应用程序。',
  joinBeta: '加入我们的测试计划',
  functions: {
    title: 'Docker生态系统',
    desc: '基于Docker的容器化部署，以快速启动和运行所需软件。',
    features: {
      feature1Subject: '开箱即用的应用',
      feature1Desc: '预装丰富的GPU应用模板',
      feature2Subject: 'GPU按需使用',
      feature2Desc: '随时随地按需使用GPU，价格领先行业',
      feature3Subject: '更好、更快、更便宜',
      feature3Desc: '极简算力使用，极致节省成本'
    }
  },
  featuresTitle: '为什么选择NiceGPU?',
  features: [{
    title: '最新的GPU',
    summary: '访问最先进的GPU模型，以确保您的AI/ML任务的最佳性能。',
    propertys: [{
      name: 'GPU内存：24GB至80GB'
    }, {
      name: '高计算能力'
    }, {
      name: '高效能'
    }]
  }, {
    title: '流行框架',
    summary: '无缝运行您的应用程序，支持TensorFlow、Torch、R等。',
    propertys: [{
      name: '预装框架'
    }, {
      name: '易于集成'
    }, {
      name: '定期更新'
    }]
  }, {
    title: '快速支持',
    summary: '实时获得我们支持团队的帮助，迅速解决任何问题',
    propertys: [{
      name: '电话'
    }, {
      name: '邮箱'
    }, {
      name: '线上聊天'
    }]
  }, {
    title: '可扩展的解决方案',
    summary: '根据项目要求向上或向下扩展计算资源。',
    propertys: [{
      name: '自定义配置'
    }, {
      name: '灵活的计费选项'
    }, {
      name: '未来由你造就'
    }]
  }, {
    title: '安全的环境',
    summary: '我们通过强大的安全措施确保您的数据安全和隐私。',
    propertys: [{
      name: '数据加密'
    }, {
      name: '定期安全审计'
    }, {
      name: '符合行业标准'
    }]
  }, {
    title: '高性能',
    summary: '体验闪电般快速的处理速度，为您的研发提速。',
    propertys: [{
      name: '硬件优化'
    }, {
      name: '低延迟'
    }, {
      name: '可靠运行'
    }]
  }],
  integration: {
    title: 'API无缝集成',
    content: {
      list1: '轻松地将NiceGPU集成到您现有的工作流程中。我们的API支持所有主要的AI/ML框架，使您能够轻松地自动化任务和扩展项目。',
      importNiceGpu: '导入NiceGPU',
      list2: ' 开始加速您的应用程序！'
    }
  },
  supercharge: {
    title: '准备好为你的项目充电了吗？',
    content: {
      list1: '立即加入NiceGPU，利用最新的GPU技术和可扩展的解决方案，满足您的AI/ML需求。',
      signUpNow: '立即加入'
    }
  }
};
