import { EnumActivitiesType } from '@/views/ComponentsArithmeticManagement/InstanceActivities';

export const arithmeticManagement = {
  btns: {
    newInstance: 'New'
  },
  overview: {
    title: 'Overview',
    resourcesOfNumber: 'Resources',
    running: 'Running',
    academicAcceleration: 'Academic Acceleration',
    academicAccelerationAbility: 'Academic acceleration ability, after turning on can improve part of the academic resources platform access, download acceleration',
    accelerationSettings: 'Acceleration settings'
  },
  compute: {
    title: 'My Compute',
    immediateUse: 'Use Now',
    createLimits: ['The current account can only create two instances. If you want to create more instances, please destroy the current instance or', ' contact us', '.'],
    totalCu: 'Total Compute',
    used: 'Used',
    left: 'Remaining',
    date: 'Valid Date'
  },
  applicationTip: {
    env: 'Environment configuration: '
  },
  instance: {
    title: 'Instances',
    gpuMemory: 'GPU Memory',
    arithmeticPower: 'Arithmetic power',
    systemDisk: 'System Disk',
    network: 'Network',
    cloudDisk: 'Cloud disk',
    expired: 'Expired',
    validTime: 'Due Date',
    publicIp: 'Public IP:',
    arithmeticConnection: 'Arithmetic connection',
    statusDesc: 'The instance `Name` is in the `Status` state and this operation is not supported.',
    renewTooltip: 'This instance `Name` has expired, please renew.',
    more: 'More',
    turnOff: 'Shutdown',
    turnOn: 'Turn On',
    restart: 'Restart',
    renew: 'Renew',
    hasNoCanUsedCu: 'No NCU available.',
    cloudDiskExpansion: 'Cloud disk expansion',
    destroy: 'Destroy',
    cancelBidding: 'Cancel bidding',
    loginService: {
      username: 'Username',
      password: 'Password',
      userInfo: 'Account Info'
    },
    table: {
      tips: {
        title: 'Common Issues:',
        lists: ['The duration of the initialization process can vary depending on the application template used and current network conditions; it typically takes about 5-10 minutes.', 'When an instance has just started and transitioned to a running state, the computing connection provided may not be immediately available, requiring an approximate wait of 2-5 minutes.']
      },
      title: 'Lists',
      refresh: 'Refresh',
      name: 'Name',
      status: 'Status',
      model: 'GPU Type',
      computeType: 'Type',
      columnGPUMemory: 'GPU Memory',
      columnCPUMemory: 'Memory',
      paymentMethod: 'Billing',
      paymentByCu: 'By NCU',
      createTime: 'Create Time',
      endTime: 'Due Time',
      connection: 'Compute Connection',
      activities: 'Activities',
      viewActivities: 'View',
      action: 'Action',
      noData: 'There are currently no instances available.',
      systemErrorTooltip: 'System exception, this instance will not consume NCU.'
    },
    activitiesDescriptions: {
      [EnumActivitiesType.Create]: 'Created',
      [EnumActivitiesType.Delete]: 'Deleted',
      [EnumActivitiesType.IncreaseCU]: 'Renewed',
      [EnumActivitiesType.Restart]: 'Restart',
      [EnumActivitiesType.Shutdown]: 'Shutdown',
      [EnumActivitiesType.StartUp]: 'Start Up',
      [EnumActivitiesType.ReturnCU]: 'Return NUM NCU to your account'
    },
    destroyDialog: {
      title: 'Destroy',
      desc: 'Are you sure you want to destroy the instance: \'Instance\'?',
      sumbitSuccess: 'Destroyed successfully.',
      sumbitSuccessWithNcu: 'Destroyed successfully, NUMNCU returned to your account.'
    },
    restartDialog: {
      title: 'Restart',
      desc: 'Make sure you want to restart the instance: \'Instance\'?',
      sumbitSuccess: 'Submission successful, restarting.'
    },
    turnOffDialog: {
      title: 'Shut Down',
      tip: 'You will perform a <span style="font-weight: bold;">Shut Down</span> operation for the following services, please confirm.',
      open: 'Open',
      close: 'Collapse',
      table: {
        name: 'Name/GPU',
        region: 'Region',
        powerConfig: 'Compute',
        cloudDisk: 'System Disk'
      },
      sumbitSuccess: 'Submission successful. Shutting down.'
    },
    startUpDialog: {
      title: 'Start Up',
      tip: 'You will perform a <span style="font-weight: bold;">Start Up</span> operation for the following services, please confirm.',
      open: 'Open',
      close: 'Collapse',
      table: {
        name: 'Name/GPU',
        region: 'Region',
        powerConfig: 'Compute',
        cloudDisk: 'System Disk'
      },
      sumbitSuccess: 'Submission successful. Powering up.'
    },
    renewDialog: {
      title: 'Renew',
      titleCanUseCu: '(Available for {cu}NCU)',
      tip: 'You will perform a <span style="font-weight: bold;">renewal</span> operation on the following services. After renewal, the instance can run until {time}. Please confirm.',
      open: 'Open',
      close: 'Collapse',
      table: {
        name: 'Name/GPU',
        region: 'Region',
        powerConfig: 'Compute',
        cloudDisk: 'System Disk'
      },
      sumbitSuccess: 'Submission successful. Powering up.',
      form: {
        useCu: 'NCU to be Used'
      }
    },
    confirmButton: 'Confirm',
    cancelButton: 'Cancel'
  },
  common: {
    refreshSuccess: 'Refresh Successful',
    refresh: 'Refresh'
  }
};
