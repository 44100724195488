export const dashboard = {
  titlePre: 'Providing',
  title: 'Accelerate Your AI and ML Projects',
  desc: 'Leverage the power of our latest GPUs and popular frameworks to run your AI/ML applications faster and more efficiently.',
  joinBeta: 'Join our Beta List',
  functions: {
    title: 'Docker Ecosystem',
    desc: 'Docker-based container and image deployment to get all the software you need up and running quickly.',
    features: {
      feature1Subject: 'Out of The Box Deployment',
      feature1Desc: 'Pre Configured Deployment of Your Favorite Template',
      feature2Subject: 'On-Demand GPU Access',
      feature2Desc: 'Instant and Permissionless access to the internet of GPUs with Industry Breaking Prices',
      feature3Subject: 'Better, Faster and Cheaper',
      feature3Desc: 'Industry Breaking Prices'
    }
  },
  featuresTitle: 'Why Choose NiceGPU?',
  features: [{
    title: 'Latest GPUs',
    summary: 'Access the most advanced GPU models to ensure optimal performance for your AI and ML tasks.',
    propertys: [{
      name: 'GPU Memory: 24GB to 80GB'
    }, {
      name: 'High compute power'
    }, {
      name: 'Energy-efficient architecture'
    }]
  }, {
    title: 'Popular Frameworks',
    summary: 'Run your applications seamlessly with support for TensorFlow, Torch, R, and more.',
    propertys: [{
      name: 'Pre-installed frameworks'
    }, {
      name: 'Easy integration'
    }, {
      name: 'Regular updates'
    }]
  }, {
    title: 'Quick Support',
    summary: 'Get prompt assistance from our dedicated support team to resolve any issues swiftly',
    propertys: [{
      name: 'Phone Call Support'
    }, {
      name: 'Email Support'
    }, {
      name: 'Online Chat Support'
    }]
  }, {
    title: 'Scalable Solutions',
    summary: 'Scale your computing resources up or down based on your project requirements.',
    propertys: [{
      name: 'Customizable configurations'
    }, {
      name: 'Flexible billing options'
    }, {
      name: 'You input, shape the future'
    }]
  }, {
    title: 'Secure Environment',
    summary: 'Ensure the safety and privacy of your data with our robust security measures.',
    propertys: [{
      name: 'Data encryption'
    }, {
      name: 'Regular security audits'
    }, {
      name: 'Complicance with industry standards'
    }]
  }, {
    title: 'High Performance',
    summary: 'Experience lightning-fast processing speeds to accelerate your research and development.',
    propertys: [{
      name: 'Optimized hardware'
    }, {
      name: 'Low latency'
    }, {
      name: 'Reliable uptime'
    }]
  }],
  integration: {
    title: 'Seamless API Integration',
    content: {
      list1: 'Integrate NiceGPU effortlessly into your existing workflows. Our comprehensive API supports all major AI/ML frameworks, enabling you to automate tasks and scale your projects with ease.',
      importNiceGpu: 'Import NiceGPU',
      list2: ' and start accelerating your applications today!'
    }
  },
  supercharge: {
    title: 'Ready to Supercharge Your Projects?',
    content: {
      list1: 'Join NiceGPU today and take advantage of the latest GPU technology, robust support, and scalable solutions tailored to your AI and ML needs.',
      signUpNow: 'Sign Up Now'
    }
  }
};
