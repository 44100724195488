<script setup lang="ts">
import { removeToken, removeUserInfo } from '@/utils/auth';
import { useRoute, useRouter } from 'vue-router';
import FeedBack from '@/components/feedBack/FeedBack.vue';
import { ref } from 'vue';
import { initUserInfo, useUserInfoStore } from '@/stores/userInfo';
import UserLogin from '@/views/UserLogin.vue';
import { whiteList } from '@/router';
import LayoutHeaderItems from './LayoutHeaderItems.vue';
const userInfoStore = useUserInfoStore();
const router = useRouter();
const route = useRoute();

const USERINFO_LOGOUT = 'logout';

const onUserInfoCommand = (command: string) => {
  if (command === USERINFO_LOGOUT) {
    removeToken();
    removeUserInfo();
    userInfoStore.userInfo = initUserInfo();
    router.push({
      path: '/dashboard',
      query: {
        redirect: route.fullPath
      }
    });
  }
};

const feedBackRef = ref();
const onFeedbackClick = () => {
  feedBackRef.value?.onFeedbackButtonClick();
};

const loginDialogVisible = ref(false);
const onSignInClick = () => {
  loginDialogVisible.value = true;
};
const onDialogClose = () => {
  loginDialogVisible.value = false;
};

</script>

<template>
  <div class="layout-header-wrap" :class="{'layout-header-wrap_white-list': whiteList.includes(route.path)}">
    <div id="layout-header" class="layout-header" nav-mode="dark">
      <div class="layout-header-nav" id="inline-nav">
        <div class="layout-header-nav__left">
          <div class="layout-header-nav-logo">
            <img src="../../assets/image/logo.png">
            <span>{{ $t('common.appName') }}</span>
            <span class="beta">
              Beta
            </span>
          </div>
        </div>
        <div class="layout-header-nav__right">
          <LayoutHeaderItems @onFeedbackClick="onFeedbackClick"></LayoutHeaderItems>
          <div class="layout-header-nav-user-info" v-if="userInfoStore.userInfo.token">
            <div class="layout-header-nav-user-info-account">
              <p class="layout-header-nav-user-info-account-name">{{ userInfoStore.userInfo.name }}</p>
            </div>
            <el-dropdown :teleported="false" class="user-info-dropdown" @command="onUserInfoCommand">
              <img :src="userInfoStore.userInfo.icon" alt="">
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="USERINFO_LOGOUT">{{ $t('login.logoutBtn') }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="login" v-if="!userInfoStore.userInfo.token">
            <el-button @click="onSignInClick">{{ $t('login.loginBtn') }}</el-button>
          </div>
        </div>
      </div>
      <div class="layout-header-items_mobile">
        <LayoutHeaderItems></LayoutHeaderItems>
      </div>
    </div>
  </div>
  <FeedBack ref="feedBackRef"></FeedBack>
  <UserLogin :visible="loginDialogVisible" @onDialogClose="onDialogClose"></UserLogin>
</template>

<style lang="scss" scoped src="./LayoutHeader.scss"></style>
