export const apiClick = () => {
  const dom = document.querySelector('.dashboard-view .dashboard-integration');
  if (dom) {
    window.scrollTo(0, (dom as any).offsetTop - 32);
  }
};

export const featuresClick = () => {
  const dom = document.querySelector('.dashboard-view div.features');
  if (dom) {
    window.scrollTo(0, (dom as any).offsetTop - 32);
  }
};
