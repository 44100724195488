import type { BaseResponse } from '../http/response';
import type { ApplicationItem, EnumRegion, LoginServiceItem } from '../applications/getApplications';
import type { ResourcesItem } from './resources';
export enum EnumInstanceStatus {
  running = 'Running',
  closed = 'ShutDown',
  closing= 'ShuttingDown',
  startingUp= 'StartingUp',
  initializing = 'Initializing',
  bidding = 'Bidding',
  exception = 'Exception'
}
export interface GetInstancesRequest {}
export interface GetInstancesResponseDataInstanceItem {
  'id': string;
  'application': ApplicationItem;
  'timezone': string;
  'available_end_at': number;
  'create_at': number;
  'create_user': string;
  'instance_name': string;
  'instance_msg': string;
  'instance_status': EnumInstanceStatus;
  'last_action': 'system-shutdown' | null;
  'last_edit_at': number;
  'last_edit_user': string;
  'login_services': Array<LoginServiceItem>;
  'public_ip_addresses': Array<string>;
  'enable': boolean;
  'region': EnumRegion;
  'service_id': string;
  'resource': ResourcesItem;
  'used_cu': number;
  'total_disk': number;
  'gpu_count': number;
}
export type GetInstancesResponse = BaseResponse<{
  'running_count': number;
  'total_count': number;
  'instances': Array<GetInstancesResponseDataInstanceItem>
}>
