<script setup lang="ts">
import ChangeLanguage from '@/components/changeLanguage/ChangeLanguage.vue';
import i18n from '@/lang';
import { useUserInfoStore } from '@/stores/userInfo';
import { apiClick, featuresClick } from './LayoutMain';
const userInfoStore = useUserInfoStore();

const emits = defineEmits(['onFeedbackClick']);

const onFeedbackClick = () => {
  emits('onFeedbackClick');
};

const onHelpCenterClick = () => {
  const lang = i18n.global.locale.value.toLocaleLowerCase();
  window.open(`${window.location.origin}/helpcenter/${lang}/compute_management/`);
};

const onFeaturesClick = () => {
  featuresClick();
};

const onApiClick = () => {
  apiClick();
};

</script>

<template>
  <div class="layout-header-nav-setting layout-header-nav-setting_share">
    <div class="layout-header-nav-setting-icon pad">
      <span class="share-link">
        <a href="https://node.svc.nicegpu.com/login.html" target="_blank">{{ $t('common.shareLink.title') }}</a>
      </span>
    </div>
  </div>
  <div class="layout-header-nav-setting" v-if="userInfoStore.userInfo.token">
    <div class="layout-header-nav-setting-icon pad">
      <span @click="onFeedbackClick" class="feedback">{{ $t('common.feedback.title') }}</span>
    </div>
  </div>
  <div v-if="!userInfoStore.userInfo.token" class="layout-header-nav-setting">
    <div class="layout-header-nav-setting-icon pad">
      <span @click="onFeaturesClick">{{ $t('common.footer.features') }}</span>
    </div>
  </div>
  <div v-if="!userInfoStore.userInfo.token" class="layout-header-nav-setting">
    <div class="layout-header-nav-setting-icon pad">
      <span @click="onApiClick">{{ $t('common.footer.api') }}</span>
    </div>
  </div>
  <div class="layout-header-nav-setting">
    <div class="layout-header-nav-setting-icon pad">
      <span @click="onHelpCenterClick" class="help-center">{{ $t('common.helpCenter.title') }}</span>
    </div>
  </div>
  <div class="layout-header-nav-setting layout-header-nav-setting_language">
    <div class="layout-header-nav-setting-icon pad">
      <div class="icon-language">
        <ChangeLanguage></ChangeLanguage>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./LayoutHeaderItems.scss"></style>
