<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import zhTw from 'element-plus/es/locale/lang/zh-tw';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import en from 'element-plus/es/locale/lang/en';
import { computed, watch } from 'vue';
import i18n from '@/lang';
import { EnumLanguage } from './enums/language';
import LayoutMain from './components/layout/LayoutMain.vue';
import { useUserInfoStore } from './stores/userInfo';
import { setUserInfo } from './utils/auth';
import { setLanguage } from './utils/localStorage';
const route = useRoute();
watch(() => route.path, () => {
  if (route.query.lan) {
    i18n.global.locale.value = route.query.lan as string;
  }
}, {
  immediate: true
});
watch(() => i18n.global.locale.value, () => {
  setLanguage(i18n.global.locale.value);
});

const userInfoStore = useUserInfoStore();
watch(() => userInfoStore.userInfo.total_cu, () => {
  if (userInfoStore.userInfo.token) {
    setUserInfo(userInfoStore.userInfo);
  }
});

const locale = computed(() => {
  if (i18n.global.locale.value === EnumLanguage.LAN_TRANDITIONAL_CHINESE.toLocaleLowerCase()) {
    return zhTw;
  } else if (i18n.global.locale.value === EnumLanguage.LAN_CHINESE.toLocaleLowerCase()) {
    return zhCn;
  } else {
    return en;
  }
});

</script>

<template>
  <el-config-provider :locale="locale">
    <div class="app-container" >
      <layout-main>
        <div class="router-body">
          <RouterView />
        </div>
      </layout-main>
    </div>
  </el-config-provider>
</template>
<style>
@import './style/elDropdown.scss';
@import './style/elPopper.scss';
@import './style/elMessage.scss';
@import './style/elDialog.scss';
@import './style/elTable.scss';
@import './style/ngCheck.scss';
@import './style/elCheckbox.scss';
* {
  margin: 0;
  padding: 0;
  font-family: -apple-system,BlinkMacSystemFont,"pingfang SC","Hiragina Sans GB","Helvetica Neue",Helvetica,"microsoft yahei ui","microsoft yahei",simsun,arial,sans-serif;
}
:root {
  --el-color-primary: #0052d9;
}
</style>
<style lang="scss" scoped>
@import './style/elForm.scss';
@import './style/elButton.scss';
@import './style/elInputNumber.scss';
@import './style/elSelect.scss';
@import './style/elInput.scss';
.app-container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  .router-body {
    height: 100%;
  }
}
</style>
