export const env: any = {
  // local preview
  'localhost:9527': {
    api: 'https://genesis-gdev.newegg.org/e8c0d1e5'
  },
  'nicegpu.newegg.lt': {
    api: 'https://genesis-gdev.newegg.org/e8c0d1e5'
  },
  'nicecuda.newegg.lt': {
    api: 'https://genesis-gdev.newegg.org/dc334f65'
  },
  'tensorlink-demo.newegg.lt': {
    api: 'https://genesis-gdev.newegg.org/dc334f65'
  },
  'tensorlink-demo.newegg.dev': {
    api: 'https://tensorlink-demo.newegg.dev/apis'
  },
  'www.nicecuda.com': {
    api: 'https://www.nicecuda.com/apis'
  },
  'www.nicegpu.com': {
    api: 'https://www.nicegpu.com/apis'
  }
};
