export enum EnumHttp {
  SUCCESS = 200,
  SERVICE_ERROR = 500,
  NO_REVIEW = 400,
  LIMIT = 403,
  ANALYSISED_BUT_COLLECTING_ITEM = 404,
  NOT_ANALYSIS_ITEM = 403,
  LOGIN_ERROR = 401,
  // join beta
  JOIN_BETA_SUBMITTED = 409,
  // contact us
  CONTACT_US_SUBMITED = 409,
  // create instance
  LIMITED_WHEN_CREATE_INSTANCE = 413,
  SERVICE_ERROR_WHEN_CREATE_INSTANCE = 500
}
